import { useEffect, useReducer, useState } from 'react';
import Styles from '../Styles.module.css';
import { baseURL, storage, toaster, user } from '../../functions/O3developer';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import PageTopBar from '../../components/PageTopBar';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom';
import { loadingReducer } from '../../reducers/loading-reducer';
import error from '../../json/error.json';
import PulseLoader from "react-spinners/PulseLoader";

function UserToUser() {
    const navigate = useNavigate();

    const [amount, setAmount] = useState('');
    const [loading, dispatch] = useReducer(loadingReducer, false);  
    const [userInfo, setUserInfo] = useState('');
    const [pin, setPIN] = useState('');
    const [loadingRequest, dispatchRequest]   = useReducer(loadingReducer, false); 

    async function proceed(e) {
        e.preventDefault();        
        if (amount == "") return toaster("error", "Please input an amount");
        if (userInfo == "") return toaster("error", "Please enter beneficiary email or username");                   
        if (pin == "") return toaster("error", "Please input your transaction pin");
        if (!loadingRequest){
            dispatchRequest({type: 'start'});   
            axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
            await axios.post(baseURL+"/fund-wallet/user-to-user",  {
                amount: amount,
                userInfo: userInfo,
                pin: pin,
          }).then((response) => {
            dispatchRequest({type: 'stop'});
            if (response.data.status == "failed" && response.data.message !== "") {
                return toaster("error", response.data.message);
              } else if (response.data.status == "success") {
                return toaster("success", response.data.message);          
            } else {
            return toaster("error", "Transfer failed please try again");    
            }

      
            }).catch((e)=>{
                if (e.response !== undefined) {
                    if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                        storage("remove", "data", null, "localStorage");
                        return navigate("/login");
                    }
                  }
                    return toaster("error", error.loading.excemption);
            }) 
        }       
    }

  
  
      useEffect(() => { return () => axios.CancelToken.source(); }, []);


    return (
      <div>
  
  
      <PageTopBar title="Fund Transfer" />

  {/* <div className="mb-5"></div> */}

  
  <div className="page-title-clear"></div>
    <div className="page-content">


  <ToastContainer /> 

  <div className="card dashboard-layout card-style">
<div className="content mb-0">
<h3>Transfer

<span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '8px', borderRadius: '50%'}}>
      <img src="/dashboard/images/user-to-user.png" className="text-center" width="39" style={{  borderRadius: "50%"  }} />
      </span></h3>
<p className={ Styles.description_txt }>
    Enter amount you wish to transfer to other user.
</p>
<br/>

<div hidden={!loading}> <SkeletonTheme height={40}> <Skeleton count={2}/> </SkeletonTheme> </div>

<div hidden={loading}>

<div className="input-style input-style-always-active has-borders has-icon validate-field mb-4">
<i className="fas fa-user" style={{ color: 'red' }}></i>
<input type="text" value={userInfo} onChange={(e) => setUserInfo(e.target.value)} className="form-control validate-name shadow-bg-m shadow-s" id="form1ab" placeholder="Enter username or email address" />
{/* <label for="form1ab" className="color-theme opacity-50 text-uppercase font-700 font-10">Amount</label> */}
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>


<div className="input-style input-style-always-active has-borders has-icon validate-field mb-4">
<i className="fas fa-arrow-right" style={{ color: 'green' }}></i>
<input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} min="0" max="999999" className="form-control validate-name shadow-bg-m shadow-s" id="form1ab" placeholder="Amount" />
{/* <label for="form1ab" className="color-theme opacity-50 text-uppercase font-700 font-10">Amount</label> */}
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>


<div className="input-style input-style-always-active has-borders has-icon validate-field mb-4">
<i className="fas fa-arrow-right" style={{ color: 'blue' }}></i>
<input type="number" value={pin} onChange={(e) => setPIN(e.target.value)} min="0" max="9999" className="form-control validate-name shadow-bg-m shadow-s" id="form1ab" placeholder="Transaction pin" />
{/* <label for="form1ab" className="color-theme opacity-50 text-uppercase font-700 font-10">Amount</label> */}
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>


<a href={void(0)} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s mb-4 " + Styles.proceedBtn } onClick={proceed} >
    
{ loadingRequest ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
   { loadingRequest ? '' : 'Proceed' }
    
     </a>

    </div>


    <div hidden={!loading} className='text-center mt-2 mb-3'> <div className="spinner-border text-success" style={{width:"20px", height: "20px"}} role="status"> </div> </div>

</div>
</div>
  
  
  </div>
      </div>
    )
  
  }
  
  export default UserToUser;
  