import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import WalletCard from "../components/dashboard/WalletCard"
// import SummaryCard from "../components/dashboard/SummaryCard"
import AgentCard from "../components/dashboard/AgentCard";
// import VirtualAccount from "../components/dashboard/VirtualAccount";
// import PlayCard from '../components/dashboard/PlayCard';
import Notification from "../components/dashboard/Notification";
import ReferralCard from '../components/dashboard/ReferralCard';
import Services from "../components/dashboard/Services";
import { baseURL, scrollToTop, toaster, user, storage, siteName, sweetalert} from '../functions/O3developer';
// import Social from '../components/Social';
import WhatsappCard from '../components/WhatsappCard';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FloatingWhatsApp from 'react-floating-whatsapp';
import { loadingReducer } from '../reducers/loading-reducer';
import error from '../json/error.json';

// import SweetAlert from 'react-bootstrap-sweetalert';
import Footer from "../components/Footer";

function Dashboard () {
  useEffect(() => {
    scrollToTop();
      AOS.init();
      dashboardRequest();
      
   } , []);



  const navigate = useNavigate();
  const [loading, dispatch] = useReducer(loadingReducer, false);
  const [wallet, setWallet] = useState('');  
  const [commission, setCommission] = useState('');
  const [verification, setVerification] = useState('');
  const [notification, setNotification] = useState('');
  const [whatsappNo, setWhatsappNo] = useState('');
  const [whatsappLink, setWhatsappLink] = useState('');
  const [bonus, setBonus] = useState('');

  const dashboardRequest = async () => {
      dispatch({type: 'start'});
      await axios.post(baseURL+"/user/dashboard",  {
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      }, 
  }).then((response) => {
          // console.log(response.data);
        if (response.data.status == "failed" && response.data.message !== "") {
          // return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
            setWallet(response.data.user.wallet);
            setCommission(response.data.user.commission);
            setVerification(response.data.user.verification);
            setNotification(response.data.notification.message);
            setBonus(response.data.referral.commission);
            setWhatsappNo(response.data.whatsapp.number);
            setWhatsappLink(response.data.whatsapp.link);
            if (response.data.notification.message !== null) {
              sweetalert(response.data.notification.message);              
            }
        }
      }).catch((e) => {   
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          // return toaster("error", error.loading.excemption);
          // return e;
      })
  }

 

  return (
    <div>
      <div className="dashboard-layout">
        {/* <Notification message={notification} /> */}
        <WalletCard user={user()} wallet={wallet} notification={notification} verification={verification}/>
        {/* <SummaryCard commission={commission}/>          */}
        {/* <AgentCard/> */}        
        {/* <VirtualAccount/> */}
        {/* <Social mt="9px" mb="9px"/> */}
        {/* <PlayCard/> */}
        <ReferralCard user={user()} commission={commission}/>
        <WhatsappCard link={whatsappLink} /> 
        <Services/>
        {/* <Footer /> */}
    </div>

        <FloatingWhatsApp
            phoneNumber={whatsappNo !== '' ? whatsappNo : null}
            accountName={siteName}
            statusMessage="Customer care representative"
            avatar = "/dashboard/images/avater.png"
            chatMessage="Hello, What can I do to assist you?"
            allowClickAway
            // notification
            // notificationDelay={60000} // 1 minute
            // notificationSound
        />







    </div>

  )
}

export default Dashboard
