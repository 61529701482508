import axios from 'axios';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../components/dashboard/VirtualAccount.module.css'
import LoadingModal from '../../components/modals/LoadingModal';
import { ToastContainer } from 'react-toastify';
import { baseURL, siteLogo, storage, toaster, user } from '../../functions/O3developer';
import error from '../../json/error.json';
import { loadingReducer } from '../../reducers/loading-reducer';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CopyToClipboard from 'react-copy-to-clipboard';
import PageTopBar from '../../components/PageTopBar';
import { PulseLoader } from 'react-spinners';
import Styles from '../Styles.module.css';
import Countdown from 'react-countdown';


function DynamicAccount () {

    const navigate = useNavigate();
    async function proceed() {
      setDisplay(false);
      if (amount == "") return toaster("error", "Please input an amount");
      dispatch({type: 'start'});            
        await axios.post(baseURL + "/fund-wallet/dynamic-virtual-account",  {
          "amount": amount,
        }, {    
          headers:{
              'Authorization':`Bearer ${user().token}`,
              'Content-Type': 'application/json'
          }, 
        }).then((response) => {
            // console.log(response.data);
          if (response.data.status == "failed" && response.data.message !== "") {
            dispatch({type: 'stop'});
            return toaster("error", response.data.message);
          } else if (response.data.status == "success") {            
                setAccounts(response.data.account);
                setDisplay(true);
                dispatch({type: 'stop'});
                
          } else {
            return toaster("error", error.loading.excemption);    
          }

        }).catch((e) => {
          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }
            return toaster("error", error.loading.excemption);
        })
    }



    const [account, setAccounts] = useState([]);
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [amount, setAmount] = useState('');
    const [display, setDisplay] = useState(false);

    useEffect(() => { return () => axios.CancelToken.source(); }, []);


    function AccountCard(props) {

      let imgBaseURL = "/dashboard/images/";
      let bankImg = siteLogo;
      const bank = props.bankName;
      if (bank == "Wema bank") {
        bankImg = imgBaseURL + "wema-bank-transfer-code.jpg";
      } else if (bank == "Moniepoint Microfinance Bank") {
        bankImg = imgBaseURL + "moniepoint-logo.svg";
      } else if (bank == "Sterling bank") {
        bankImg = imgBaseURL + "sterling_ussd.jpg";      
      } else if (bank == "Fidelity bank") {
        bankImg = imgBaseURL + "Fidelity-Bank.jpg";
      } 



    const onCompleteHandler = () => setDisplay(false);
      // Random component
    const Completionist = () => <span><b>expired</b></span>;

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a complete state
        return <Completionist />;
      } else {
        // Render a countdown
        return (
          // <span>
          //   {hours}:{minutes}:{seconds}
          // </span>

          <span>
            <b> {minutes}:{seconds}mins</b>
          </span>
        );
      }
    };

      return (
        <div>
         <table className='table table-striped table-bordered mb-3' style={{ width:'100%', marginBottom: '0px' }}>
      <tbody>
          <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}>                    
           Account Number
             
          </td>
        <td style={{ fontSize: '14px' }}> <span className='color-highlight'> <b> { props.number } </b> </span> </td>
        <CopyToClipboard text={props.number}
          onCopy={() => toaster("success", "Account number successfully copied to clipboard")}>
            
               <td style={{ fontSize: '12px' }}> <span className="ms-2 badge border" style={{backgroundColor: 'green', color: "white", cursor: "context-menu"}}> <i className='fas fa-copy'></i> Copy </span> </td>

        </CopyToClipboard>       
      </tr>

      <tr>

      <td colSpan='1' scope='row' style={{ fontSize: '12px' }}> Bank Name </td>


          <td colSpan='2' style={{ fontSize: '12px'}}> <span style={{ color: "red"}}><b> 
            <span className='font-11' style={{ display: "flex", justifyContent: "space-between" }}> 
            <span style={{ fontSize: "13px", color: "red", fontWeight: "bold" }}> { props.bank } </span>
           {/* <img src='/dashboard/images/wema-bank-transfer-code.jpg' style={{ borderRadius: "20%" }} width={'30px'}/> */}
        </span>  </b> </span> </td>

      </tr>


      <tr hidden={false}>
          <td colSpan='2' style={{ fontSize: '12px' }}> 
            <span className='tranx_service_font'> Account Name - {props.name}  </span>           
          </td>
          <td style={{ fontSize: '12px' }}> 
            <span className='color-highlight'>
              <Countdown date={Date.now() + 2400000} renderer={renderer} onComplete={onCompleteHandler}/>              
            </span> 
          </td>

      </tr>


    </tbody>
  </table>

          <div style={{ textAlign: "center", fontWeight: "bold" }}> Charge: {props.chargeType == "VAT" ? "₦" : ""}{props.charge}{props.chargeType == "FLAT" ? "%" : ""} </div>
 
        </div>
      )
    }
  






  return (
    

<div>
          
      
    <PageTopBar title="Dynamic Funding" />

    <div className="page-title-clear"></div>
    <div className="page-content">



{/* <LoadingModal text="Please wait while fetching account number." key="load" visibility={loading}/> */}

<ToastContainer />

{/* <div style={{ textAlign: "center", margin: "40px 0px" }}><img src='/dashboard/images/monnify.png' /></div> */}






  <div className="card dashboard-layout card-style">
  <div className="content mb-0">

    <div hidden={display}>

  <h3> No BVN / NIN required

  <span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '8px', borderRadius: '50%'}}>
        <img src="/dashboard/images/monnify_logo.png" className="text-center" width="39" style={{  borderRadius: "50%"  }} />
        </span></h3>
  <p className={ Styles.description_txt }>
      Enter amount you wish to fund your wallet and pay into the generated account.
  </p>





  <div className="input-style input-style-always-active has-borders has-icon validate-field mb-4">
  <i className="fas fa-arrow-right" style={{ color: 'green' }}></i>
  <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} min="0" max="999999" className="form-control validate-name shadow-bg-m shadow-s" id="form1ab" placeholder="Amount" />
  {/* <label for="form1ab" className="color-theme opacity-50 text-uppercase font-700 font-10">Amount</label> */}
  <i className="fa fa-times disabled invalid color-red-dark"></i>
  <i className="fa fa-check disabled valid color-green-dark"></i>
  <em>(required)</em>
  </div>



  <a href={void(0)} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s mb-2 " + Styles.proceedBtn } onClick={proceed} >
      
  { loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
    { loading ? '' : 'Proceed' }
      
      </a>

      </div>


      <div hidden={!display}>

      <p className='mb-2'> Make a payment of ₦{amount} into the below account </p>

      <AccountCard name={account["name"]} number={account["number"]} bank={account["bankName"]} ussd={account["ussdPayment"]} charge={account["charge"]} chargeType={account["chargeType"]} /> 

      <p className='mt-2' style={{ color: "red", fontWeight: "bold", marginBottom: "10px" }}> Kindly be aware that this account number remains valid for only 40 minutes from the current time. Any funds transferred to this account will be credited automatically. Please refrain from saving the account number. </p>

    
      </div>





      <div hidden={!loading} className='text-center mt-2'> <div className="spinner-border text-success" style={{width:"20px", height: "20px"}} role="status"> </div> </div>

  <div className='text-center mt-2 mb-3'>
      {/* <img src='/dashboard/images/mastercard-visa-verve.png' width={'170'}/> */}
  </div>

  </div>
  </div>
  



    </div>



    </div>


  )
}

export default DynamicAccount
