import React, { useEffect, useReducer, useState } from 'react'
import { baseURL, scrollToTop, storage, toaster, user} from '../../functions/O3developer';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import PageTopBar from '../../components/PageTopBar';
import Styles from '../Styles.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import error from '../../json/error.json';
import { loadingReducer } from '../../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';

function LinkBVN() {

    const navigate = useNavigate();
    async function update (e) {
        e.preventDefault();
        if (bvn == "") return toaster("error", error.validation.requiredInput);
        if (type == "") return toaster("error", error.validation.requiredInput);
        if (bvn.length !== 11) return toaster("error", "BVN digit must be 11");
        if(!loading) {
            dispatch({type: 'start'});
            axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
            await axios.post(baseURL+"/user/kyc/link-bvn-nin",  {                
                bvn: bvn,
                type: type,
            }).then((response) => {
                // console.log(response.data);
            if (response.data.status == "failed" && response.data.message !== "") {
                dispatch({type: 'stop'});
                return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
                    dispatch({type: 'stop'});
                    setTimeout(() => {
                        navigate("/");
                    }, 2000);
                    return toaster("success", response.data.message);
                } else {
                    dispatch({type: 'stop'});
                    return toaster("error", error.loading.excemption);    
                }
            }).catch((e) => {   
                dispatch({type: 'stop'});
            if (e.response !== undefined) {
                if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                    storage("remove", "data", null, "localStorage");
                    return navigate("/login");
                }
            }
                return toaster("error", error.whoops);
                // return e;
            })
        }

    }
    
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [bvn, setBVN] = useState('');
    const [type, setType] = useState('');

    useEffect(() => {
        scrollToTop();
    }, [])


  return (
    <div>        
        
  <ToastContainer /> 

            <PageTopBar title="KYC"/>

        <div className="page-title-clear"></div>
        
        <div className="page-content">

        <div className="card dashboard-layout card-style">

        <div className='content mb-2'>

        <h3> Link BVN / NIN
        <span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '8px', borderRadius: '50%'}}>
            <img src="/dashboard/images/fingerprint-verified.jpeg" className="text-center" width="39" style={{  borderRadius: "50%"  }} />
            </span></h3>
        <p className={ Styles.description_txt }>           
            Kindly be aware that in accordance with the directive from the Central Bank of Nigeria (CBN), it is now necessary to associate your BVN/NIN with your virtual account.
        </p>


        <div className="input-style has-borders no-icon mb-4">
        <select id="smile" onChange={(e) => setType(e.target.value)} value={type} className="shadow-bg shadow-bg-m shadow-s">
            <option value=""> Please select  </option>
            <option value="NIN"> NIN  </option>
            <option value="BVN"> BVN  </option>
        </select>
            <span><i className="fa fa-chevron-down"></i></span>
            <i className="fa fa-check disabled valid color-green-dark"></i>
            <i className="fa fa-check disabled invalid color-red-dark"></i>
            <em></em>
        </div>



        <div className="input-style has-borders has-icon validate-field mb-4 col-lg-12">
        <i className="fas fa-arrow-right"></i>
        <input type="text" value={bvn} onChange={e => setBVN(e.target.value)} maxLength={"20"} className="form-control" placeholder="Enter number" autoComplete="off" required />
        <label htmlFor="form1" className="color-highlight">BVN</label>
        <i className="fa fa-times disabled invalid color-red-dark"></i>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <em>(required)</em>
        </div>



        <a href="#" onClick={update} className="btn btn-full btn-sm rounded-s font-600 font-13 mb-3" style={{ color: "white", backgroundColor: "blue" }}>
        {loading ? <PulseLoader color={"white"} loading={true} size={8} /> : "Proceed"}  </a>


        <p>Dial: <b>*565*0#</b> to check BVN | <b>*346#</b> to check NIN </p>
        </div>

        

        </div>





    </div>

    </div>
  )
}

export default LinkBVN